import http from "../http-common";

class ConsultaDataService {
  getAll(params) {
    return http.get("/consulta", {
      params,
    });
  }
  getAllConsultas(idPacienteConsulta) {
    return http.get(`/consulta?idPacienteConsulta=${idPacienteConsulta}`);
  }
  getAllSeguimiento(params) {
    return http.get("/consulta/seguimiento", { params });
  }
  get(id) {
    return http.get(`/consulta/${id}`);
  }
  addConsulta(data) {
    return http.post("/consulta", data);
  }
  update(id, data) {
    return http.put(`/consulta/${id}`, data);
  }

}

export default new ConsultaDataService();
